body,
html {
  margin: 0;
  padding: 0 20px;
  min-height: 75vh;
}
* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  /* background-color: #006bb5; */
  /* background: rgb(0,107,181); */
/* background: rgb(0,107,181);
background: linear-gradient(90deg, rgba(0,107,181,1) 0%, rgb(2 40 66) 100%); */

/* radial  */
background: rgb(0,107,181);
background: radial-gradient(circle, rgba(0,107,181,1) 0%, rgb(2 40 66) 100% 100%);


/* linear 301
background: rgb(0,107,181);
background: linear-gradient(301deg, rgba(0,107,181,1) 0%, rgb(2 40 66) 100%);
*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: "PoppinsNormal";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Poppins"), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}