body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttonVersion1{
  background-image: linear-gradient(to right, #cdefff, #7cc9ed);
  color: #387794;
  font-size: 16px;
  width: 200px;
  height: 50px;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-bottom: 0;
  overflow: hidden;
  position: relative;
  -webkit-transform: translatez(0);
          transform: translatez(0);
}


/* Spacing */ 
.spacer {
  background: transparent;
  width: 100%;
  height: 30px;

}

.dividerShape {
  margin: 0 auto;
  align-items: center;
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #ffffff, #95d0fb,#ffffff);

}

.dividerShapeDotted {
  float: right;
   width: 100%;
   height: 2px;
  border: #3c6cb2 dashed 1px;
 }

.headerShape {
  width: 100%;
  height: 10px;
  background-image: linear-gradient(to right, #c4d34f, #f0f6c3);
  padding-left: -25px;
}

/* Aligning */ 
.marginCenter {
  margin: 0 auto;
}

/*
.pageGradient {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: auto;
  margin: auto;
  z-index: -1;
  background: radial-gradient( #96dcfc, #7cc9ed);
  transition: all 0s ease-out 0s;
  transform: translatex(-140px);
  animation: 13s linear 0s infinite move;
}

@keyframes move {
  0% {
    transform: translatex(-140px);
  }
  25% {
    transform: translatex(140px);
    opacity: 0.3;
  }
  50% {
    transform: translatex(440px);
    opacity: 1;

  }
  75% {
    transform: translatex(-140px);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    transform: translatex(-140px);
  }
}
*/



/* Button Animation */
.buttonGradient {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: auto;
  margin: auto;
  z-index: -1;
  background: radial-gradient(90px circle at top center, #7cc9ed, #92d9f9);
  transition: all 0s ease-out 0s;
  -webkit-transform: translatex(-140px);
          transform: translatex(-140px);
  -webkit-animation: 10s linear 0s infinite moveButton;
          animation: 10s linear 0s infinite moveButton;
}

@-webkit-keyframes moveButton {
  0% {
    -webkit-transform: translatex(-140px);
            transform: translatex(-140px);
  }
  25% {
    -webkit-transform: translatex(140px);
            transform: translatex(140px);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: translatex(140px);
            transform: translatex(140px);
    opacity: 1;
    background: radial-gradient(90px circle at bottom center,  #7cc9ed, #92d9f9);
  }
  75% {
    -webkit-transform: translatex(-140px);
            transform: translatex(-140px);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    -webkit-transform: translatex(-140px);
            transform: translatex(-140px);
    background: radial-gradient(90px circle at top center,  #7cc9ed, #92d9f9);
  }
}

@keyframes moveButton {
  0% {
    -webkit-transform: translatex(-140px);
            transform: translatex(-140px);
  }
  25% {
    -webkit-transform: translatex(140px);
            transform: translatex(140px);
    opacity: 0.3;
  }
  50% {
    -webkit-transform: translatex(140px);
            transform: translatex(140px);
    opacity: 1;
    background: radial-gradient(90px circle at bottom center,  #7cc9ed, #92d9f9);
  }
  75% {
    -webkit-transform: translatex(-140px);
            transform: translatex(-140px);
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    -webkit-transform: translatex(-140px);
            transform: translatex(-140px);
    background: radial-gradient(90px circle at top center,  #7cc9ed, #92d9f9);
  }
}


/* Fade Animation */

.fadePanel {
  -webkit-animation: fadeInAnimation ease 2s;
          animation: fadeInAnimation ease 2s; 
  -webkit-animation-iteration-count: 1; 
          animation-iteration-count: 1; 
  -webkit-animation-fill-mode: forwards; 
          animation-fill-mode: forwards; 

}

@-webkit-keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
  } 
}

@keyframes fadeInAnimation { 
  0% { 
      opacity: 0; 
  } 
  100% { 
      opacity: 1; 
  } 
} 

/* Pulse Animation */ 
.pulse{
  margin: 800px  0 0 100px;
  -webkit-filter:
  drop-shadow(.2rem .2rem 1rem #7cc9ed);
          filter:
  drop-shadow(.2rem .2rem 1rem #7cc9ed);
  color: #7cc9ed;
  width: 8px;
  height: 8px;
  position: absolute;
  background: #cf4038;
  -webkit-animation: 
  pulsing 1s ease infinite alternate;
          animation: 
  pulsing 1s ease infinite alternate;

}

@-webkit-keyframes pulsing {
  0%, 100% {
    background-color: #7cc9ed;
  }

  25% {
    background-color: #a8dcf4;
  }

  50% {
    background-color: #d2f1ff;
  }

  75% {
    background-color: #f3fbff;
  }

}

@keyframes pulsing {
  0%, 100% {
    background-color: #7cc9ed;
  }

  25% {
    background-color: #a8dcf4;
  }

  50% {
    background-color: #d2f1ff;
  }

  75% {
    background-color: #f3fbff;
  }

}



/* Scanner animation */
.scanner {
  position:relative;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.scanner .fingarprint {
  position: relative;
  margin: 0 auto;
  background-size: 300px;
}

.scanner .fingarprint::before {
 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 
  background-size: 300px;
  -webkit-animation: animate 4s ease-in-out infinite;
          animation: animate 4s ease-in-out infinite;
}


@-webkit-keyframes animate {
  0%, 100% {
      height:0%;
  }
  
  50% {
      height:100%;
  }
}


@keyframes animate {
  0%, 100% {
      height:0%;
  }
  
  50% {
      height:100%;
  }
}

.scanner .fingarprint::after {
  content: '';
 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: #3fefef;
  border-radius: 8px;
  -webkit-filter: drop-shadow(0 0 20px rgb(96, 96, 96)) drop-shadow(0 0 60px #000);
          filter: drop-shadow(0 0 20px rgb(96, 96, 96)) drop-shadow(0 0 60px #000);
  -webkit-animation: animate_line 4s ease-in-out infinite;
          animation: animate_line 4s ease-in-out infinite;
}

@-webkit-keyframes animate_line {
  0%,
  100% {
      top: 0%;
  }
  50% {
      top: 100%;
  }
}

@keyframes animate_line {
  0%,
  100% {
      top: 0%;
  }
  50% {
      top: 100%;
  }
}

/* Scanner animation 2 */
.scannerHand {
  position:relative;
  display:flex;
  flex-direction:column;
  align-items:center;
}

.scannerHand .handPrint {
  position: relative;
  margin: 0 auto;
  background-size: 300px;
}

.scannerHand .handPrint::before {
 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 300px;
  -webkit-animation: animate 3s ease-in-out infinite;
          animation: animate 3s ease-in-out infinite;
}


@keyframes animate {
  0%, 100% {
      height:0%;
  }
  
  50% {
      height:100%;
  }
}

.scannerHand .handPrint::after {
  content: '';
 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #4dffff;
  border-radius: 8px;
  -webkit-filter: drop-shadow(0 0 200px #a2dafb ) drop-shadow(0 0 260px #3e9bd0);
          filter: drop-shadow(0 0 200px #a2dafb ) drop-shadow(0 0 260px #3e9bd0);
  -webkit-animation: animate_line_scan 3s ease-in-out infinite;
          animation: animate_line_scan 3s ease-in-out infinite;
  
}


@-webkit-keyframes animate_line_scan {
  0%,
  100% {
      top: 0%;
  }
  50% {
      top: 100%;
  }
}


@keyframes animate_line_scan {
  0%,
  100% {
      top: 0%;
  }
  50% {
      top: 100%;
  }
}


/* Bounce animation */ 

.bounce { 

  /* width: 150px;
  height: 55px; */
   -webkit-animation-name: bounce; 
   animation-name: bounce; 
   -webkit-animation-duration: 1.3s;
   animation-duration: 1.3s; 
   -webkit-animation-fill-mode: both; 
   animation-fill-mode: both; 
}



@-webkit-keyframes bounce { 
  0%, 20%, 55%, 75%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-45px);} 
  60% {-webkit-transform: translateY(-35px);} 
} 
@keyframes bounce { 
  0%, 20%, 55%, 75%, 100% {-webkit-transform: translateY(0);} 
  40% {-webkit-transform: translateY(-45px);} 
  60% {-webkit-transform: translateY(-35px);} 
}


/* Circle Flasher Effect */

.circleFlasher1{
  border-radius: 100%;
  width: 50px;
  margin: 90px 0 0 350px;
  position: absolute;
  height: 50px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
  background: #e5f6ff;
  -webkit-animation: 
  circleFlasherPulse 2s ease infinite alternate;
          animation: 
  circleFlasherPulse 2s ease infinite alternate;

}

.circleFlasher2{
  border-radius: 100%;
  width: 50px;
  margin: 50px 0 0 250px;
  position: absolute;
  height: 50px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
  background: #e5f6ff;
  -webkit-animation: 
  circleFlasherPulse 2s ease infinite alternate;
          animation: 
  circleFlasherPulse 2s ease infinite alternate;

}

.circleFlasher3{
  border-radius: 100%;
  width: 50px;
  margin: 90px 0 0 140px;
  position: absolute;
  height: 50px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
  background: #e5f6ff;
  -webkit-animation: 
  circleFlasherPulse 2s ease infinite alternate;
          animation: 
  circleFlasherPulse 2s ease infinite alternate;

}

.circleFlasher4{
  border-radius: 100%;
  width: 50px;
  margin: 310px 0 0 25px;
  position: absolute;
  height: 50px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
  background: #e5f6ff;
  -webkit-animation: 
  circleFlasherPulse 2s ease infinite alternate;
          animation: 
  circleFlasherPulse 2s ease infinite alternate;

}

.circleFlasher5{
  border-radius: 100%;
  width: 50px;
  margin: 180px 0 0 450px;
  position: absolute;
  height: 50px;
  -webkit-filter: blur(1px);
          filter: blur(1px);
  opacity: 0.5;
  background: #e5f6ff;
  -webkit-animation: 
  circleFlasherPulse 2s ease infinite alternate;
          animation: 
  circleFlasherPulse 2s ease infinite alternate;

}



@-webkit-keyframes circleFlasherPulse {
  0%, 100% {
    background-color: #56daff;
  }

  25% {
    background-color: rgb(136, 229, 255);
  }

  50% {
    background-color: #9ce9ff;
  }

  75% {
    background-color: #bef1ff;
  }

  85% {
    background-color: #eafbff;
  }

  93% {
    background-color: #fff;
  }

}



@keyframes circleFlasherPulse {
  0%, 100% {
    background-color: #56daff;
  }

  25% {
    background-color: rgb(136, 229, 255);
  }

  50% {
    background-color: #9ce9ff;
  }

  75% {
    background-color: #bef1ff;
  }

  85% {
    background-color: #eafbff;
  }

  93% {
    background-color: #fff;
  }

}





/* Pattern */ 



.gridPattern{
background-color: white;
opacity: 1;
background-image: radial-gradient(#ffffff 1.5px, transparent 0.5px);
background-size: 10px 10px;

width: 100%;
height: 100%;
} 
body,
html {
  margin: 0;
  padding: 0 20px;
  min-height: 75vh;
}
* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  /* background-color: #006bb5; */
  /* background: rgb(0,107,181); */
/* background: rgb(0,107,181);
background: linear-gradient(90deg, rgba(0,107,181,1) 0%, rgb(2 40 66) 100%); */

/* radial  */
background: rgb(0,107,181);
background: radial-gradient(circle, rgba(0,107,181,1) 0%, rgb(2 40 66) 100% 100%);


/* linear 301
background: rgb(0,107,181);
background: linear-gradient(301deg, rgba(0,107,181,1) 0%, rgb(2 40 66) 100%);
*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(/static/media/Montserrat-VariableFont_wght.a3fcdff0.ttf) format('truetype');
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(/static/media/Poppins-ExtraLight.6f8391bb.ttf) format('truetype');
}

@font-face {
  font-family: "PoppinsNormal";
  src: local("Poppins"), url(/static/media/Poppins-Regular.093ee89b.ttf) format('truetype');
}

@font-face {
  font-family: "PoppinsBold";
  src: local("Poppins"), url(/static/media/Poppins-Bold.08c20a48.ttf) format('truetype');
}
